import React, { useContext } from "react";
import { GlobalDataContext } from "../../../context/context";
import TransparentHeader from "../../global/TransparentHeader";
import BaseLayout from "../../global/BaseLayout";



function Form3() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Form3">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Looking to Invest in Real Estate"
                    Subheader="Looking to Invest in Real Estate"
                    bgimg={`${rpdata?.stock?.[7]}`}
                />
                <div className="my-5 md:flex justify-center items-center">
                    <div className="justify-center p-10 m-5 md:w-[800px]">
                        <img className="w-full rounded" src={rpdata?.stock?.[5]} alt="imgform"/>
                    </div>
                    <div className="md:w-full border-2 p-5 m-6">
                        <div class="elfsight-app-937d0179-8c1d-4d20-9f77-284ad99a5fd0"></div>
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default Form3;
