import React, { useContext } from "react";
import { GlobalDataContext } from "../../../context/context";
import TransparentHeader from "../../global/TransparentHeader";
import BaseLayout from "../../global/BaseLayout";


function Form1() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Form1">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Dream Home Finder"
          Subheader="Dream Home Finder"
          bgimg={`${rpdata?.stock?.[7]}`}
        />
       <div className="my-5">

        <div class="elfsight-app-e5cc63d2-6bbe-4218-b00a-c543409ebfb0"></div>
       </div>
      </div>
    </BaseLayout>
  );
}

export default Form1;
