import React, { useContext } from "react";
import { GlobalDataContext } from "../../../context/context";
import TransparentHeader from "../../global/TransparentHeader";
import BaseLayout from "../../global/BaseLayout";



function Form2() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Form2">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Free Home Eveluation"
                    Subheader="Free Home Eveluation"
                    bgimg={`${rpdata?.stock?.[7]}`}
                />
                <div className="my-5">
                <div class="elfsight-app-7f52c4b8-98ca-481b-9588-7599c2a97d5c"></div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default Form2;
